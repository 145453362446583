.main-header {
    background-color: #fff;
    padding: 20px 0px;
}

.main-header-container {
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}

.custom-menu-primary {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.menu-item a {
    display: block;
    color: #000000;
    text-decoration: none;
    outline: none;
    font-weight: 600;
    padding: 12px 10px;
    line-height: 1.3;
}

.menu-item {
    padding: 0px 20px;
}

.childmenu-wrapper {
    margin: 0;
    padding: 0px 0px;
    list-style-type: none;
    
}

.menu-item.has-submenu {
    position: relative;
}

.menu-item.has-submenu > a {
    position: relative;
    cursor: default;
    padding-right: 15px 
}

.menu-item.has-submenu > a:after {
    content: "";
    height: 8px;
    width: 8px;
    border: 1px solid;
    display: inline-block;
    transform: rotate(45deg);
    position: absolute;
    top: calc(50% - 6px);
    right: 0px;
}


.menu-item.has-submenu .has-submenu > a:after {
    right: 12px;
    top: calc(50% - 4px);
}

.childmenu-wrapper  .menu-item {
    padding: 0px 0px;
}

.childmenu-wrapper .menu-item a {
    padding: 15px 20px;
}

.childmenu-wrapper .childmenu-wrapper {
    left: 100%;
    top: 0px;
    margin-top: -5px;
    background-color: #fafafa;
}

.childmenu-wrapper li:hover > a {
    background-color: #f0f0f0;
}
.custom-menu-wrapper {
    width: 100%;
}

.triggerbtn {
    display: block;
    /* height: 40px;
    width: 40px;
    background-color: red; */
    cursor: pointer;
}

@media(min-width: 768px) {
    .mobile-trigger {
        display: none;
    }
    .header-logo {
        width: 177px;
    }
    .custom-menu-wrapper {
        width: calc(100% - 177px);
    }
    .custom-menu-primary {
        display: flex;
        justify-content: end;
    }
    .custom-menu-primary > .menu-item.has-submenu:not(:hover) > a:after {
        border-top: 0px;
        border-left: 0px;
    }
    
    .custom-menu-primary > .menu-item.has-submenu:hover > a:after {
        border-bottom: 0px;
        border-right: 0px;
        top: calc(50% - 2px);
    }
    .menu-item.has-submenu:hover > .childmenu-wrapper {
        opacity: 1;
        visibility: visible;
        pointer-events: all;
    }
    .childmenu-wrapper {
        padding: 5px 0px;
        position: absolute;
        background-color: #ffffff;
        max-width: 300px;
        width: 300px;
        z-index: 2;
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
        transition: all .2s;
        border-radius: 10px;
    }
    .menu-item.has-submenu .has-submenu > a:after {
        transform: rotate(130deg);
        border-bottom: 0px;
        border-right: 0px;
    }
    .childmenu-wrapper .childmenu-wrapper {
        left: 100%;
        top: 0px;
        margin-top: -5px;
    }
}

@media(max-width: 767px) {
.custom-menu-primary {
    background-color: #ffffff;
}
.main-header-container {
    width: 100%;
    position: relative;
}
.mobile-trigger, .header-logo {
    padding-right: 15px;
    padding-left: 15px;
}
.menu-item a {
    padding: 12px 15px
}
.menu-item.has-submenu > a:after{
    right: 18px;
    border-top: 0px;
    border-left: 0;
}
.childmenu-wrapper .menu-item a {
    padding: 12px 15px;
}
.menu-item.has-submenu .has-submenu > a:after {
    right: 18px;
}
.menu-item.has-submenu > a {
    padding-right: 27px;
}
.childmenu-wrapper {
    display: none;
    background-color: #f3f3f3;
}
.childmenu-wrapper.active {
    display: block;
}
.menu-item.has-submenu > a.active:after {
    border-top: 1px solid;
    border-left: 1px solid;
    border-bottom: 0;
    border-right: 0px;
    top: calc(50% - 2px);
}
.menu-item {
    padding: 0px 0px;
}
.custom-menu-wrapper {
    display: none;
    position: absolute;
    z-index: 99;
    top: 144%;
    left: 0;
    right: 0;
    width: 100%;
    background-color: white;
    box-shadow: 0px 0px 6px -2px #dddddd;
}
.custom-menu-wrapper.menu-open {
    display: block;
}
.main-header {
    background-color: white;
    box-shadow: 0px -3px 5px 3px #dddddd;
}

}